.footer {
    justify-content: center;
    padding: 5px;
    width: 100%;
    height: auto;
    background: #DEE0E2 0% 0% no-repeat padding-box;
}

.footercontainer {
    margin-top: 80px;
    display: inline-block;
}

#footlinks {
    margin-right: 20px;
    color: #808080;
    font-size: 16px;
}