

.paygrid {
    grid-template-columns: 30% 40% 30%;
    display: grid;
    background-color: #F5F5F5;
}

.paymiddle {
    margin-top: 40px;
    margin-bottom: 40px;
    grid-column: 2/3;
    border-color: black;
    border-style: solid;
    border-width: 1px;
    justify-content: center;
    align-items: center;
}

.paybuttoncontainer {
    display: block;
    justify-content: center;
    align-items: center;
    align-items: center;
    justify-content: center;
    background-color: #F5F5F5;
}

.dot {
    height: 20px;
    width: 20px;
    background-color: #624B99;
    border-radius: 50%;
    margin-bottom: 2px;
    margin-top: 4px;
    display: block;
}

.paybuttongrid {
    display: grid;
    grid-template-columns: 20% 80%;
    margin-left: 10px;
    margin-right: 10px;
}

.paybuttonleft {
    grid-column: 1/2;
}

.paybuttonright {
    grid-column: 2/2;
}

#singlepayment {
    width: 90%;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 5%;
    height: auto;
    text-align: left;
    font-size: 12px;
    border-radius: 10px;
    border-color: black;
    border-width: 1px;
    background-image: linear-gradient(to top, #D3D3D3, white);
}