
.container {
       padding-top: 100px;
}

.top-element {
       grid-template-columns: 100px auto 100px;
       background-color: black;
       height: 70px;
}

.middle-element {
       display: grid;
       grid-template-columns: 200px auto 200px;
       min-height: auto;
}

.twovehicledetails {
       grid-row: 1/3;
}

.navbuttons {
       display: block;
}

.two {
       grid-column: 2/3;
       grid-row: 1/3;
}

.three {
       grid-column: 2/3;
       grid-row: 2/3;
}

.straightlineback {
       padding-top: 20px;
       grid-column: 2/3;
       grid-row: 1/3;
       margin-bottom: 30px;
}

.blueline {
       background-color: #005EA5;
       height: 5px;
       width: 100%;
       grid-column: 2/3;
       grid-row: 2/3;
}

.longblueline {
       background-color: #005EA5;
       height: 5px;
       width: 100%;
}

.four {
       grid-column: 2/3;
       grid-row: 3/3;
}

#summarytable {
       float: left;
       width: 200px;
       color: #808080;
       border-bottom: none;
       border-bottom-width: 0;
}